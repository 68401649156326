import {toKey} from '@/lib/utils/strings';
import Link from 'next/link';
import React, {useEffect, useState} from 'react';
import {v} from '../../utils/validation';
import {SectionFC} from '../../types/templates';
import {loadArchiveCategories, loadNews} from '../../utils/loaders';
import {NewsPost} from '../../types/news';
import {useRouter} from 'next/router';
import {extractFirstQueryValue} from '@/lib/utils/nextjs';
import {Category} from '../../types/category';
import {NewsPreview} from '../news/NewsPreview';

export type NewsFields = {
  enabled: boolean;
  filterByCategory?: string;
};

export type NewsProps = Readonly<NewsFields>;

export function extractIDByArchive(
  archive: string,
  categories: Category[]
): number | undefined {
  for (const category of categories) {
    if (category.slug === archive) {
      return category.id;
    }
  }
  return undefined;
}
let loadingMessage = 'News is loading...';
export async function getNewsByArchive(archive: string, setNews) {
  const result = await loadArchiveCategories('news');
  if (result.ok) {
    const categoryId = extractIDByArchive(archive, result.data);
    getNews(categoryId, setNews);
  }
}
export async function getNews(category: number, setNews) {
  if (category === undefined) {
    setNews([]);
    console.error('Category is undefined');
    loadingMessage = 'No news items.';
    return;
  }
  const result = await loadNews({categories: [category], perPage: 3});
  if (result.ok) {
    result.data.length === 0 && (loadingMessage = 'No news items.');
    setNews(result.data);
  } else {
    setNews([]);
  }
}

/**
 * Renders a news preview section
 * @param customField The custom field describing a list of news articles
 */
export const News: SectionFC<NewsFields> = props => {
  const [news, setNews] = useState<NewsPost[]>([]);
  const router = useRouter();
  const archive = extractFirstQueryValue(router, 'archive');
  if (!News.canShow(props.content)) return null;

  useEffect(() => {
    getNewsByArchive(archive, setNews);
  }, [archive]);

  const newsElements =
    news.length > 0 ? (
      news.map((newsPost: NewsPost) => (
        <NewsSummary key={toKey(newsPost.title)} newsPost={newsPost} />
      ))
    ) : (
      <div>{loadingMessage}</div>
    );

  return (
    <article className="news stack" data-testid="news-summaries">
      <h2>News</h2>
      {newsElements}
      <Link href={`/${archive}/news`} className="btn btn-outline-primary">
        View all news
      </Link>
    </article>
  );
};

/**
 * Determines if the News component can be shown with the given properties
 * @param props - The properties of the news determining if this news can be shown
 * @returns boolean.
 */
News.canShow = content => {
  return content.enabled;
};

News.fields = v.object({
  enabled: v.boolean('visible').default(false),
  filterByCategory: v.string('filter_by_category').optional(),
});

export const NewsFeedValidation = {
  acfFcLayout: v.literal('acf_fc_layout', 'news_feed'),
  newsFeed: v.object({
    enabled: v.boolean('news_feed.visible').default(false),
  }),
};
/**
 * Renders a news article summary
 * @param newsArticle The article to render a summary for
 */
function NewsSummary(props: {readonly newsPost: NewsPost}) {
  return (
    <NewsPreview
      key={props.newsPost.id}
      title={props.newsPost.title}
      content={props.newsPost.content}
      slug={props.newsPost.slug}
      createdDate={props.newsPost.createdDate}
      newsCategories={props.newsPost.newsCategories}
      featuredMediaId={props.newsPost.featuredMediaId}
    />
  );
}

export const __testing__ = {NewsSummary};
